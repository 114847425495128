import React, { useEffect, useState } from 'react';
import { OpenSeaAsset, OpenSeaResponse } from '../models/OpenSeaModels';
import Asset from './Asset';
import useInterval from '../hooks/useInterval';

export interface GalleryParams {
    ethAddress: string;
}

const Gallery: React.FC<GalleryParams> = (params: GalleryParams) => {

    const { ethAddress } = params; 
    const [nftAssets, setAssets] = useState<OpenSeaAsset[]>([]);
    const [doneLoading, setDoneLoading] = useState(false);
    const [currentAssetIndex, setCurrentAssetIndex] = useState(0);



    const cycleAssets = () => {
      if (!doneLoading) return;

      if (currentAssetIndex === nftAssets.length - 1) {
        setCurrentAssetIndex(0);
      } else {
        const nextIndex = currentAssetIndex + 1;
        setCurrentAssetIndex(nextIndex);
      }
    }

    useInterval(cycleAssets, 7000);

    const loadNfts = async (next: string | null, runningAssets: OpenSeaAsset[]) => {

      let apiQuery = `https://api.opensea.io/api/v1/assets?owner=${ethAddress}`;

      if (next) {
        apiQuery = `${apiQuery}&cursor=${next}`;
      }

      const assetsResponse = await fetch(apiQuery);
      
      if (assetsResponse.ok) {
        const json: OpenSeaResponse = await assetsResponse.json();
        const { next, assets } = json;

        const newAssets = [...runningAssets, ...assets];

        if (json.next) {
          await loadNfts(next, newAssets);
        } else {
          setAssets(newAssets);
          setDoneLoading(true);
        }
      }
    }

    useEffect(() => {
      if (ethAddress !== '') {
        setCurrentAssetIndex(0);
        loadNfts(null, []);
      }
      
    }, [ethAddress]);

    const renderAssets = () => {
      if (nftAssets.length === 0 || !doneLoading) {
        return null;
      }

      return nftAssets.map((asset) => <Asset key={asset.id} asset={asset} />);
    }

    const renderCurrentAsset = () => {
      if (nftAssets.length === 0 || !doneLoading) {
        return null;
      }
      const currentAsset = nftAssets[currentAssetIndex];
      return <Asset asset={currentAsset} />;
    }    

    return (
      <div style={{ textAlign: 'center' }}>
          { renderCurrentAsset() }
      </div>
    );
}

export default Gallery;