import React, { useRef, useEffect, useState } from 'react';
import jsQR, { QRCode } from 'jsqr';
import Gallery from './Gallery';

const ImageCapture: React.FC = () => {

    //let canvasElement: HTMLCanvasElement | null = useRef;
    const canvasElementRef = useRef<HTMLCanvasElement>(null);
    const [lastQr, setLastQr] = useState('');
    let canvasContext: CanvasRenderingContext2D | null;
    let video: HTMLVideoElement = document.createElement('video');
    let qrValid: boolean = false;

    interface Point {
        x: number;
        y: number;
    }

    const drawLine = (begin: Point, end: Point, color: string) => {
        if (canvasContext) {
          canvasContext.beginPath()
          canvasContext.moveTo(begin.x, begin.y)
          canvasContext.lineTo(end.x, end.y)
          canvasContext.lineWidth = 3
          canvasContext.strokeStyle = color
          canvasContext.stroke()
        }
    }

    const drawVideoFrame = () => {
      
        if (!canvasContext || !canvasElementRef || !canvasElementRef.current) {
          // we can't draw video frame
          return
        }

        const canvasElement = canvasElementRef.current

        if (video.readyState === video.HAVE_ENOUGH_DATA) {
            
          // draw video frame from camera and mark found QR code in frame
          canvasElement.height = video.videoHeight
          canvasElement.width = video.videoWidth
          canvasContext.drawImage(video, 0, 0, canvasElement.width, canvasElement.height)
          const imageData: ImageData = canvasContext.getImageData(0, 0,
            canvasElement.width, canvasElement.height)
          const code: QRCode | null = jsQR(imageData.data, imageData.width, imageData.height)
          if (code) {
            // draw rectangle around found code in video frame
            const color = qrValid ? '#409e26' : '#FF3B58';
            drawLine(code.location.topLeftCorner, code.location.topRightCorner, color)
            drawLine(code.location.topRightCorner, code.location.bottomRightCorner, color)
            drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, color)
            drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, color)
            // QR code found

            if (code.data && code.data !== '') {
              const codeData = code.data;
              const cleanedData = codeData.replace('ethereum:', '').replace('@1', '');
              setLastQr(cleanedData);

              setTimeout(drawVideoFrame, 4000);
            } else {
              requestAnimationFrame(drawVideoFrame);
            }

            

          } else {
            // no QR code found
            requestAnimationFrame(drawVideoFrame);
          }
        }

        //requestAnimationFrame(drawVideoFrame);
        
      }

    useEffect(() => {
      
      if (canvasElementRef && canvasElementRef.current) {
        const canvasElement = canvasElementRef.current;
        canvasContext = canvasElement.getContext('2d');
        
        if (canvasContext) {
            if (canvasContext && navigator.mediaDevices) {
                
                navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
                  .then(async (stream: MediaStream) => {
                    video.srcObject = stream
                    // no full screen for iOS
                    video.setAttribute('playsinline', 'true')
                    await video.play()
                    video.playbackRate = 0.5
                    drawVideoFrame();
                  }).catch(async (error) => {
                    console.log(error);
                  });
              }
        }
      }
    }, []);



    return (
    <div style={{ textAlign: 'center' }}>
        
        { lastQr === '' ? (
          <div>
            <h1>Hold Up your ETH QR Code to Get Started!</h1>
            <h2>(you may have to play with the brightness)</h2>
            <h3>(the dimmer the better...)</h3>
          </div>
        ) : (null) }
        <canvas style={{ display: lastQr !== '' ? 'none' : 'none' }} ref={canvasElementRef}></canvas>
        <Gallery ethAddress={lastQr} />
    </div>
    );
}

export default ImageCapture;