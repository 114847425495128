import React from 'react';
import { OpenSeaAsset } from '../models/OpenSeaModels';

export interface AssetParams {
    asset: OpenSeaAsset;
}

const Asset: React.FC<AssetParams> = (params: AssetParams) => {
    const { asset } = params; 
    let hasVideo = false;
    let portrait = window.innerWidth < window.innerHeight;
    let width = window.innerWidth / 2;
    
    if (portrait) {
        width = window.innerWidth;
    }

    if (asset.animation_url && asset.animation_url !== '') {
        hasVideo = true;
    }

    return (
    <div style={{ maxWidth: '100%' }}>
        <h2 className='asset-title' >{asset.name}</h2>
        { 
            hasVideo ? (
                <video width={width} autoPlay className='asset-image' src={asset.animation_url} />
            ) : 
            (
                <img width={width} className='asset-image' src={asset.image_url} alt={asset.name} />
            ) 
        }
        
    </div>
    );
}

export default Asset;