import React from 'react';
import './App.css';

import ImageCapture from './components/ImageCapture';

function App() {
  
  const fullScreen = () => {
    window.document.documentElement.requestFullscreen();
  };

  return (
    <div onClick={fullScreen}>
      <ImageCapture />
    </div>
  );
}

export default App;
